const ru = {
  AttendancePivotTable: {
    Student: "Студент",
    Visited: "Посещено, %",
    Hours: "Количество ак. часов",
    Month: "Месяц",
    Lesson: "Урок",
    Date: "Дата",
    Duration: "Продолжительность",
    Teacher: "Преподаватель",
    Days: "Дни",
    Time: "Время",
    Course: "Курс: {{course, string}}",
    GroupSchedule: "График проведения занятий в группе {{group, string}}",
    Total: "Программа {{hours, number}} ак. часов",
    CompletedPeriod: "Проведено за период {{hours, number}} ак. часов",
    LeftPeriod: "Осталось на конец периода {{hours, number}} ак. часов",
    NotPerformedToPayPeriod: "Отмены сверх нормы {{hours, number}} ак. часов",
    TotalInTable: "Программа",
    CompletedInTable: "Проведено за период",
    NotPerformedToPayHours: "Отмены сверх нормы: ",
    LeftInTable: "Осталось на конец периода",
    MinHours: "ак.ч.",
  },
  ReportsAttendance: {
    Title: "Посещаемость",
    AllGroups: "Все группы",
    SelectGroup: "Выбор группы",
    EmptyGroupAndCompany: "Выберите компанию или группу чтобы увидеть отчет",
    Download: "Выгрузить",
    AllCompanies: "Все компании",
    SelectCompany: "Выберите компанию",
    EmptyLessons:
      "На данный момент у вас нет завершенных занятий по выбранным фильтрам, и поэтому построить отчет по посещаемости невозможно",
    OnlyTeachersLessons: "Только мои занятия",
  },
  AttendancePivotReportDownload: {
    Sheet: "Сводный отчет",
  },
  DatePicker: {
    MaskPlaceholder: "ДД/ММ/ГГГГ",
    Today: "Сегодня",
    Clear: "Очистить",
  },
  GroupsAutocompleteFilter: {
    EnterGroupName: "Введите название группы",
    SelectGroup: "Выбор группы",
  },
  Loading: {
    Error: "Ошибка загрузки, попробуйте обновить страницу",
  },
  ModalGroupReport: {
    Teacher: "Преподаватель",
    Course: "Курс",
    Schedule: "Расписание",
    Duration: "Продолжительность",
  },
  GroupFilter: {
    SearchStudent: "Поиск студента",
    StartDate: "Дата начала",
    EndDate: "Дата завершения",
  },
  MonthName: {
    "1": "Январь",
    "2": "Февраль",
    "3": "Март",
    "4": "Апрель",
    "5": "Май",
    "6": "Июнь",
    "7": "Июль",
    "8": "Август",
    "9": "Сентябрь",
    "10": "Октябрь",
    "11": "Ноябрь",
    "12": "Декабрь",
  },
  ShortWeekDay: {
    "0": "ВС",
    "1": "ПН",
    "2": "ВТ",
    "3": "СР",
    "4": "ЧТ",
    "5": "ПТ",
    "6": "СБ",
  },
  Progress: {
    totalProgress: "Общий прогресс",
    completed: "Готово",
    yourProgress: "Ващ прогресс:",
  },
};

export default ru;
