const ur = {
  AttendancePivotTable: {
    Student: "طالب علم",
    Visited: "حاضری، %",
    Hours: "گھنٹے کی تعداد",
    Month: "مہینہ",
    Lesson: "سبق",
    Date: "تاریخ",
    Duration: "دورانیہ",
    Teacher: "استاد",
    Days: "دن",
    Time: "وقت",
    Course: "کورس {{course, string}}",
    GroupSchedule: "گروپ {{group, string}} کے شیڈول میں سبق",
    Total: "پروگرام {{hours, number}} گھنٹے",
    CompletedPeriod: "اس مدت میں مکمل ہوا {{hours, number}} گھنٹے",
    LeftPeriod: "مدت کے اختتام پر باقی {{hours, number}} گھنٹے",
    NotPerformedToPayPeriod: "اضافی منسوخیاں {{hours, number}} گھنٹے",
    TotalInTable: "پروگرام",
    CompletedInTable: "مدت میں مکمل ہوا",
    NotPerformedToPayHours: "اضافی منسوخیاں: ",
    LeftInTable: "مدت کے اختتام پر باقی",
    MinHours: "ac.h.",
  },
  ReportsAttendance: {
    Title: "حاضری",
    SelectGroup: "گروپ منتخب کریں",
    AllGroups: "تمام گروپس",
    EmptyGroupAndCompany: "رپورٹ دیکھنے کے لیے کمپنی یا گروپ منتخب کریں",
    Download: "ڈاؤن لوڈ کریں",
    AllCompanies: "تمام کمپنیاں",
    SelectCompany: "کمپنی منتخب کریں",
    EmptyLessons:
      "اس وقت، منتخب فلٹرز کے لیے آپ کے پاس مکمل کلاسز نہیں ہیں، لہذا حاضری کی رپورٹ بنانا ممکن نہیں ہے",
    OnlyTeachersLessons: "صرف میری کلاسز",
  },
  AttendancePivotReportDownload: {
    Sheet: "پیوٹ رپورٹ",
  },
  DatePicker: {
    MaskPlaceholder: "dd/mm/yyyy",
    Today: "آج",
    Clear: "صاف کریں",
  },
  GroupsAutocompleteFilter: {
    EnterGroupName: "گروپ کا نام درج کریں",
    SelectGroup: "گروپ منتخب کریں",
  },
  Loading: {
    Error: "لوڈنگ میں خرابی، صفحہ کو ریفریش کرنے کی کوشش کریں",
  },
  ModalGroupReport: {
    Teacher: "استاد",
    Course: "کورس",
    Schedule: "شیڈول",
    Duration: "دورانیہ",
  },
  GroupFilter: {
    SearchStudent: "طالب علم تلاش کریں",
    StartDate: "شروع ہونے کی تاریخ",
    EndDate: "ختم ہونے کی تاریخ",
  },
  MonthName: {
    "1": "جنوری",
    "2": "فروری",
    "3": "مارچ",
    "4": "اپریل",
    "5": "مئی",
    "6": "جون",
    "7": "جولائی",
    "8": "اگست",
    "9": "ستمبر",
    "10": "اکتوبر",
    "11": "نومبر",
    "12": "دسمبر",
  },
  ShortWeekDay: {
    "0": "اتوار",
    "1": "پیر",
    "2": "منگل",
    "3": "بدھ",
    "4": "جمعرات",
    "5": "جمعہ",
    "6": "ہفتہ",
  },
  Progress: {
    totalProgress: "کل پیشرفت",
    completed: "مکمل",
    yourProgress: "آپ کی پیشرفت:",
  },
};

export default ur;
