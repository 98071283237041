import { types, SnapshotOut, Instance } from "mobx-state-tree";

export enum CodeType {
  Video = "Video",
  Vocabulary = "Vocabulary",
  Grammar = "Grammar",
  Reading = "Reading",
  Writing = "Writing",
  Speaking = "Speaking",
  Test = "Test",
  Unknown = "Unknown",
}

export const TypeModel = types.model({
  id: types.identifier,
  code: types.optional(
    types.enumeration<CodeType>("CodeType", Object.values(CodeType)),
    CodeType.Unknown
  ),
});

type TypeModelType = Instance<typeof TypeModel>;
export type Type = TypeModelType;
type TypeModelTypeSnapshotType = SnapshotOut<typeof TypeModel>;
export type TypeModelSnapshot = TypeModelTypeSnapshotType;
