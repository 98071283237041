import { Box, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";

import { Block } from "@packages/store/models/Block/Block";
import { CodeType } from "@packages/store/models/Block/Type";

import { IconWrapper } from "./styledComponents/IconWrapper";
import { Icon, IconType } from "../../components/Icon";
import { StatusTypography } from "../../components/StatusTypography";
import { StatusType } from "../../types";

interface BlockViewProps {
  block: Block;
  order: number;
  status: StatusType;
  onClick: () => void;
}

const iconTypeMap: Record<string, IconType> = {
  [CodeType.Vocabulary]: "vocabulary",
  [CodeType.Test]: "test",
  [CodeType.Video]: "video",
  [CodeType.Grammar]: "grammar",
  [CodeType.Speaking]: "speaking",
  [CodeType.Reading]: "reading",
  [CodeType.Writing]: "reading",
  [CodeType.Unknown]: "vocabulary",
  default: "vocabulary",
};

export const BlockView = observer(
  ({ block, order, status, onClick }: BlockViewProps): JSX.Element | null => {
    const theme = useTheme();

    const { name, type } = block;

    const iconType = iconTypeMap[String(type?.code)] || iconTypeMap.default;

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconWrapper status={status}>
          <Icon
            sizeSquareIcon={16}
            type={iconType}
            stroke={theme.palette.custom.colorVariant[status]}
          />
        </IconWrapper>

        <StatusTypography
          variant="regularText"
          status={status}
          onClick={onClick}
        >
          {`${order}. ${name}`}
        </StatusTypography>
      </Box>
    );
  }
);
