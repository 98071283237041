/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { uniq } from "lodash";
import { getRoot, Instance, SnapshotOut, types } from "mobx-state-tree";

import { UserRole } from "../../types";
import { withEnvironment } from "../extensions/withEnvironment";
import { RootStore } from "../RootStore/RootStore";
import { UserModel, UserSnapshot } from "../User/User";

export const PROMOCODE_KEY = "jetclass_promocode";

export interface LoginProps {
  email: string;
  password: string;
}

export const ALL_REGISTER_VALUES = [
  "firstName",
  "lastName",
  "email",
  "password",
  "phone",
  "gender",
  "birthday",
  "profileCountryId",
  "profileCityId",
  "profileDegreeId",
] as const;

export type RegisterValue = (typeof ALL_REGISTER_VALUES)[number];

export type RegisterProps = {
  [key in RegisterValue]?: string;
};

export const isRegisterValue = (value: string): value is RegisterValue =>
  ALL_REGISTER_VALUES.includes(value as RegisterValue);

export interface RestorePasswordProps {
  email: string;
}

export interface ChangePasswordProps {
  password: string;
  passwordRepeat: string;
}

export const AuthStoreModel = types
  .model("AuthStore")
  .props({
    user: types.maybeNull(UserModel),
    isPasswordSetupRequired: types.maybeNull(types.boolean),
    entranceTestId: types.maybeNull(types.string),
    dontShowExercisesResults: types.maybeNull(types.boolean),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    saveUser: (userSnapshot: UserSnapshot) => {
      self.user = UserModel.create(userSnapshot);
    },
    setPasswordSetupRequired(isChange: boolean) {
      self.isPasswordSetupRequired = isChange;
    },
    setEntranceTestId(entranceTestId: string | null) {
      self.entranceTestId = entranceTestId;
    },
    setDontShowExercisesResults(value: boolean) {
      self.dontShowExercisesResults = value;
    },
  }))
  .actions((self) => ({
    getCurrentUser: async () => {
      const result = await self.environment.api.getCurrentUser();

      if (result.kind !== "ok") {
        console.error("Error getting current user");
        return;
      }

      const { user } = result;

      self.setPasswordSetupRequired(user.changePassword);

      // Парсинг ролей из API
      // Сначала проверка должна быть по лицензиям
      if (user.licenses?.length) {
        const getResultRolesFromLicenseTypesIds = (): UserRole[] => {
          const licenseTypesIds: string[] = uniq(
            user.licenses.map((license: any) => license.licenseTypeId)
          ).map(String);

          const licenseTypeIdToRole: Record<string, UserRole> = {
            "1": UserRole.STUDENT,
            "3": UserRole.DISTRIBUTOR,
            "5": UserRole.HR,
            "8": UserRole.COORDINATOR,
          };

          return licenseTypesIds
            .filter((id) => Object.keys(licenseTypeIdToRole).includes(id))
            .map((id) => licenseTypeIdToRole[id]);
        };

        user.roles = getResultRolesFromLicenseTypesIds();

        // Проверка по полю roles только если нет лицензии
      } else if (user.roles?.length) {
        const getResultRolesFromRoles = (): UserRole[] => {
          const roleCodes: string[] = user.roles.map((role: any) => role.code);

          const roleCodeToRole: Record<string, UserRole> = {
            student: UserRole.STUDENT,
            distributor: UserRole.DISTRIBUTOR,
            coordinator: UserRole.COORDINATOR,
            payroll: UserRole.PAYROLL_ACCOUNTANT,
          };

          return roleCodes
            .filter((code) => Object.keys(roleCodeToRole).includes(code))
            .map((code) => roleCodeToRole[code]);
        };

        user.roles = getResultRolesFromRoles();

        // Если нет ни лицензий ни ролей то это юзер
      } else {
        user.roles = [UserRole.USER];
      }

      // Честно, я без понятия почему но преподаватель приходит так
      if (user.teacherIsActive && !user.roles.includes(UserRole.TEACHER)) {
        user.roles.push(UserRole.TEACHER);
      }

      self.saveUser(user);
    },
  }))
  .actions((self) => ({
    login: async (
      email: LoginProps["email"],
      password: LoginProps["password"]
    ) => {
      const result = await self.environment.api.loginUser(email, password);

      if (result.kind === "ok") {
        await self.getCurrentUser();
        (window as any).setChatUserName?.(
          `${self?.user?.firstName} ${self?.user?.lastName}`,
          self?.user?.email
        );
        return true;
      }

      if (result.kind === "unauthorized")
        getRoot<RootStore>(self).addError("Unauthorized");

      return false;
    },
    register: async (props: RegisterProps) => {
      const result = await self.environment.api.registerUser(
        props,
        localStorage.getItem(PROMOCODE_KEY) ?? undefined
      );

      if (result.kind === "ok") {
        await self.getCurrentUser();
        (window as any).setChatUserName?.(
          `${self?.user?.firstName} ${self?.user?.lastName}`,
          self?.user?.email
        );
        localStorage.removeItem(PROMOCODE_KEY);
        return true;
      }

      if (result.kind === "unauthorized")
        getRoot<RootStore>(self).addError("Unauthorized");

      if (result.kind === "bad-data") {
        return result.message;
      }

      return false;
    },
    logout: async () => {
      const result = await self.environment.api.logout();

      getRoot<RootStore>(self).cleanStore();

      return result;
    },
    restorePassword: async (email: RestorePasswordProps["email"]) => {
      const result = await self.environment.api.restorePasswordUser(email);

      if (result.kind === "ok") {
        return true;
      }

      return false;
    },
    loginByToken: async (token: string) => {
      const data = await self.environment.api.loginUserByToken(token);
      if (data.kind !== "ok") {
        return false;
      }
      await self.getCurrentUser();
      return true;
    },
    changePassword: async (password: string) => {
      const result = await self.environment.api.changeUserPassword(password);
      if (result.kind !== "ok") {
        return false;
      }
      self.setPasswordSetupRequired(false);
      return true;
    },
    confirmEmail: async (token: string) => {
      const result = await self.environment.api.confirmEmail(token);

      if (result.kind === "ok") {
        const { data } = result;
        self.user?.setIsEmailConfirmed(data.isEmailConfirmed);
      }
    },
  }))
  .views((self) => ({
    get isSingIn(): boolean {
      return Boolean(self.user);
    },
  }));

type AuthStoreType = Instance<typeof AuthStoreModel>;
export type AuthStore = AuthStoreType;
type AuthStoreSnapshotType = SnapshotOut<typeof AuthStoreModel>;
export type AuthStoreSnapshot = AuthStoreSnapshotType;
export const createAuthStoreDefaultModel = (): unknown =>
  types.optional(AuthStoreModel, {} as any);
