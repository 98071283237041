const en = {
  Classes: {
    SampleByStatus: "Sample by occupation status for the period",
    OnlyLessonsWithoutGroup: "Only classes without a group",
    Title: "Classes",
    Company: "Company",
    Group: "Group",
    Status: "Status",
    ClassIndex: "Class",
    ClassDate: "Lesson date",
    Teacher: "Teacher",
    None: "None",
    Process: "Process",
    DateRangeError:
      "You need to select a start date and an end date for processing.",
    NoDataError: "No data for processing.",
    StandardHelper: "To display the data, use at least one filter.",
    ProcessHelper: "To display the data, select the date.",
    Processing: "Data is processing",
    Type: "Type",
    Unit: "Unit",
    Duration: "Ac.h.",
  },
  ClassesDownload: {
    Download: "Download",
    Classes: "Classes",
  },
  GroupsAutocompleteFilter: {
    EnterGroupName: "Enter group name",
    SelectGroup: "Group select",
  },
  TeachersAutocompleteFilter: {
    EnterTeacherName: "Teachers not found",
    SelectTeacher: "Teacher select",
  },
  Loading: {
    Error: "Loading error, try refreshing the page",
  },
  DateRangePicker: {
    StartDate: "Start date",
    EndDate: "End date",
  },
  DatePicker: {
    Today: "Today",
    Clear: "Clear",
    MaskPlaceholder: "dd/mm/yyyy",
  },
  LessonStatuses: {
    planned: "Planned",
    completed: "Completed",
    earlyCanceledByCustomer: "Student early cancellation",
    earlyCanceledBySchool: "DS early cancellation",
    lateCanceledByCustomer: "Student late cancellation",
    lateCanceledBySchool: "DS late cancellation",
    notPerformedToPay: "Not Performed To Pay",
  },
};

export default en;
