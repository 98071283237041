const ar = {
  AttendancePivotTable: {
    Student: "الطالب",
    Visited: "نسبة الحضور, %",
    Hours: "عدد الساعات",
    Month: "الشهر",
    Lesson: "الدرس",
    Date: "التاريخ",
    Duration: "المدة",
    Teacher: "المعلم",
    Days: "الأيام",
    Time: "الوقت",
    Course: "الدورة {{course, string}}",
    GroupSchedule: "جدول الدروس في مجموعة {{group, string}}",
    Total: "البرنامج {{hours, number}} ساعات",
    CompletedPeriod: "اكتمل في الفترة {{hours, number}} ساعات",
    LeftPeriod: "متبقي في نهاية الفترة {{hours, number}} ساعات",
    NotPerformedToPayPeriod: "إلغاء متكرر {{hours, number}} ساعات",
    TotalInTable: "البرنامج",
    CompletedInTable: "اكتمل في الفترة",
    NotPerformedToPayHours: "إلغاء متكرر: ",
    LeftInTable: "متبقي في نهاية الفترة",
    MinHours: "ساعات أكاديمية",
  },
  ReportsAttendance: {
    Title: "الحضور",
    SelectGroup: "اختر المجموعة",
    AllGroups: "جميع المجموعات",
    EmptyGroupAndCompany: "اختر شركة أو مجموعة لعرض التقرير",
    Download: "تحميل",
    AllCompanies: "جميع الشركات",
    SelectCompany: "اختر الشركة",
    EmptyLessons:
      "حاليًا، لا توجد دروس مكتملة ضمن الفلاتر المحددة، ولذلك لا يمكن إعداد تقرير عن الحضور",
    OnlyTeachersLessons: "فقط دروسي",
  },
  AttendancePivotReportDownload: {
    Sheet: "تقرير محوري",
  },
  DatePicker: {
    MaskPlaceholder: "dd/mm/yyyy",
    Today: "اليوم",
    Clear: "مسح",
  },
  GroupsAutocompleteFilter: {
    EnterGroupName: "أدخل اسم المجموعة",
    SelectGroup: "اختر المجموعة",
  },
  Loading: {
    Error: "خطأ في التحميل، حاول تحديث الصفحة",
  },
  ModalGroupReport: {
    Teacher: "المعلم",
    Course: "الدورة",
    Schedule: "الجدول",
    Duration: "المدة",
  },
  GroupFilter: {
    SearchStudent: "ابحث عن الطالب",
    StartDate: "تاريخ البدء",
    EndDate: "تاريخ الانتهاء",
  },
  MonthName: {
    "1": "يناير",
    "2": "فبراير",
    "3": "مارس",
    "4": "أبريل",
    "5": "مايو",
    "6": "يونيو",
    "7": "يوليو",
    "8": "أغسطس",
    "9": "سبتمبر",
    "10": "أكتوبر",
    "11": "نوفمبر",
    "12": "ديسمبر",
  },
  ShortWeekDay: {
    "0": "الأحد",
    "1": "الاثنين",
    "2": "الثلاثاء",
    "3": "الأربعاء",
    "4": "الخميس",
    "5": "الجمعة",
    "6": "السبت",
  },
  Progress: {
    totalProgress: "إجمالي التقدم",
    completed: "مكتمل",
    yourProgress: "تقدمك:",
  },
};

export default ar;
